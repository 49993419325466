<template>
  <div>
    <nav-bar />
    <div class="fastmail-content">
      <div>
        <b>收费标准</b>
        <span style="margin: 0 20px">我的会员等级：{{ userInfo.name }}</span>
        <span><span style="color: red">升级vip</span>享受更低的收费标准</span>
      </div>
      <el-tabs v-model="activeName" class="fastmail-tabs" @tab-click="getStorage">
        <el-tab-pane v-for="(item, index) in memberList" :data="item.id" :key="index" :label="item.name" :name="index + ''">
        </el-tab-pane>
      </el-tabs>
          <div v-for="(ite, ind) in storgaeListData" :key="ind">
            <div style="margin: 20px 0;">
              <i class="el-icon-truck" style="font-size: 18px; margin-right: 5px; color: #e6a23c"></i>
              <span >{{ ite.name }}</span>
            </div>
            <el-table :data="weightList" style="width: 50%">
              <el-table-column prop="weight" label="重量" align="center"></el-table-column>
              <el-table-column :label="ite.name" align="center">
                <el-table-column label="普通地区" align="center">
                    <template>
                        {{ ite.deliver_price }} <span style="color: red;">优惠¥{{ ite.discount }}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center">
                    <template slot="header">
                      <div>
                        <span>偏远地区</span>
                         <el-tooltip placement="top" effect="light" >
                            <div slot="content">内蒙古自治区<br/>海南省<br />西藏自治区<br />青海省<br />新疆维吾尔自治区</div>
                            <i class="el-icon-question"></i>
                          </el-tooltip>
                      </div>
                    </template>
                    <template>
                        {{ ite.deliver_price }} <span style="color: red;">优惠¥{{ ite.discount }}</span>
                    </template>
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import { fastmailMemberLv, getAllStorage4, myMemberInfo } from 'network/api'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      userInfo: {},
      memberList: [],
      storgaeListData: [],
      activeName: '0',
      weightList: [{ weight: '0.5Kg一下' }, { weight: '0.5Kg-1Kg' }, { weight: '1Kg以上按量计费' }],
      show: true
    }
  },
  computed: {
    // 获取用户信息
    username () {
      return this.$store.getters.getUserInfo.username
    }
  },
  mounted () {
    this.getMemberinfo()
    this.getMemberLv()
    this.getStorage()
    window._agl && window._agl.push(['track', ['success', { t: 18 }]])
  },
  methods: {
    getMemberinfo () {
      myMemberInfo().then((res) => {
        this.userInfo = res.data
      })
    },
    getMemberLv () {
      fastmailMemberLv({ key: this.$configList[window.location.host] }).then((res) => {
        console.log(res)
        if (res.code === 1) {
          this.memberList = res.data
        }
      })
    },
    getStorage (tab) {
      const groupId = tab ? tab.$attrs.data : ''
      getAllStorage4({ key: this.$configList[window.location.host], group_id: groupId }).then((res) => {
        if (res.code === 1) {
          this.storgaeListData = res.data
          this.weightList.length = 0
          this.weightList = [{ weight: '0.5Kg一下' }, { weight: '0.5Kg-1Kg' }, { weight: '1Kg以上按量计费' }]
        }
      })
    }
  }
}
</script>

<style scoped>
.fastmail-content {
  width: 1200px;
  margin: 30px auto;
}
.fastmail-tabs {
  margin-top: 30px;
}
</style>
